.app {
  background-color: #282c34;
  color: white;
}

.app-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.app-text {
  color: #61dafb;
}

.post-card {
  color: #000;
  margin-top: 20px;
}

.new-post-box {
  background-color: #ffd86b;
  color: #000;
  padding: 20px 100px 20px 100px;
  margin-top: -50px;
}

.home {
  height: calc(100vh - 72px);
  margin-top: 72px;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.upvote .downvote {
  display: inline-block;
  cursor: pointer;
  color: #687074;
}

.upvote svg {
  transform: rotate(180deg);
}

.voted {
  color: #f42424;
}

.comment {
  border-left: 6px solid rgb(255, 0, 0);
  background-color: rgb(253, 250, 68);
}

img {
  width: 100%;
  max-width: 600px;
}

.alert-parent {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 600px;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.alert {
  position: absolute;
  width: 100%;
}
